import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { zPickMerchantWithdrawForAdminEndpointInput } from '@/general/src/merchantWithdraw/routes/pickMerchantWithdrawForAdmin/input.js'
import { toHumanMerchantWithdrawStatus } from '@/general/src/merchantWithdraw/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import type { AuthorizedAdminMe } from '@/webapp/src/lib/ctx.js'
import { useFormy } from '@/webapp/src/lib/formy.js'
import { adminMerchantWithdrawViewRoute } from '@/webapp/src/lib/routes.js'
import { withSectionWrapper } from '@/webapp/src/lib/sectionWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Link, Table } from '@/webapp/src/lib/uninty.components.js'
import { formatDate } from 'date-fns/format'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const MerchantWithdrawPicker = ({
  merchantWithdraw,
}: {
  merchantWithdraw: TrpcRouterOutput['getMerchantWithdrawsForAdmin']['merchantWithdraws'][number]
}) => {
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const pickMerchantWithdrawForAdmin = trpc.pickMerchantWithdrawForAdmin.useMutation()
  const formy = useFormy({
    initialValues: {
      merchantWithdrawId: merchantWithdraw.id,
    },
    validationSchema: zPickMerchantWithdrawForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { merchantWithdraw } = await pickMerchantWithdrawForAdmin.mutateAsync(valuesInput)
      navigate(
        adminMerchantWithdrawViewRoute.get({
          merchantWithdrawSerialNumber: merchantWithdraw.serialNumber,
        })
      )
    },
    successMessage: 'Мерчантский виздрав принят',
    resetOnSuccess: false,
  })
  useEffect(() => {
    if (formy.isSubmitting) {
      setSubmitting(true)
    } else {
      setSubmitting(false)
    }
  }, [formy.isSubmitting])
  return (
    <Link
      href="#"
      disabled={submitting}
      onClick={() => {
        formy.handleSubmit()
      }}
    >
      Взяться
    </Link>
  )
}

export const AvailableMerchantWithdraws = withSectionWrapper({
  forwardProps: (props: { me: AuthorizedAdminMe }) => props,
  showLoaderOnFetching: false,
  useQuery: () =>
    trpc.getMerchantWithdrawsForAdmin.useQuery(
      {
        status: 'picking',
      },
      {
        refetchInterval: 3_000,
      }
    ),
  setProps: ({ queryResult, me }) => ({
    merchantWithdraws: queryResult.data.merchantWithdraws,
    me,
  }),
})(({ merchantWithdraws }) => {
  return !merchantWithdraws.length ? (
    <p>Здесь пусто</p>
  ) : (
    <Table
      className="unintyTable"
      records={merchantWithdraws}
      columns={[
        { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
        {
          heading: 'Статус',
          body: (record) => toHumanMerchantWithdrawStatus(record.status),
          width: 150,
        },
        {
          heading: 'Мерчант',
          body: (record) => `#${record.merchant.serialNumber}`,
          width: 150,
        },
        {
          heading: 'Дата',
          body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
          width: 150,
        },
        {
          heading: 'Сумма',
          body: (record) => toMoney({ amount: record.amount, currency: record.currency }),
          width: 100,
        },
        {
          heading: 'Сумма, ₮',
          body: (record) => toMoney({ amount: record.amountUsdt, currency: 'usdt' }),
          width: 100,
        },
        {
          heading: null,
          body: (record) => <MerchantWithdrawPicker merchantWithdraw={record} />,
          width: 100,
        },
      ]}
    />
  )
})
