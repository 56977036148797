import { toHumanCustomerWithdrawStatus } from '@/general/src/customerWithdraw/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import { toHumanPaymentMethodType } from '@/general/src/paymentMethod/utils.shared.js'
import type { AuthorizedAdminMe } from '@/webapp/src/lib/ctx.js'
import { adminCustomerWithdrawViewRoute } from '@/webapp/src/lib/routes.js'
import { withSectionWrapper } from '@/webapp/src/lib/sectionWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Table } from '@/webapp/src/lib/uninty.components.js'
import { formatDate } from 'date-fns/format'

export const DisputingCustomerWithdraws = withSectionWrapper({
  forwardProps: (props: { me: AuthorizedAdminMe }) => props,
  showLoaderOnFetching: false,
  useQuery: () =>
    trpc.getCustomerWithdrawsForAdmin.useQuery(
      {
        status: 'disputing',
      },
      {
        refetchInterval: 3_000,
      }
    ),
  setProps: ({ queryResult, me }) => ({
    customerWithdraws: queryResult.data.customerWithdraws,
    me,
  }),
})(({ customerWithdraws }) => {
  return !customerWithdraws.length ? (
    <p>Здесь пусто</p>
  ) : (
    <Table
      className="unintyTable"
      records={customerWithdraws}
      href={(record) => adminCustomerWithdrawViewRoute.get({ customerWithdrawSerialNumber: record.serialNumber })}
      columns={[
        { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
        { heading: 'Статус', body: (record) => toHumanCustomerWithdrawStatus(record.status), width: 150 },
        {
          heading: 'Дата',
          body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
          width: 150,
        },
        {
          heading: 'Метод',
          body: (record) => toHumanPaymentMethodType(record.paymentMethodType),
          width: 200,
        },
        {
          heading: 'Сумма',
          body: (record) => toMoney({ amount: record.dealAmount, currency: record.dealCurrency }),
          width: 100,
        },
        {
          heading: 'Сумма, ₮',
          body: (record) => toMoney({ amount: record.amountUsdt, currency: 'usdt' }),
          width: 100,
        },
      ]}
    />
  )
})
