import type { ClientChat } from '@/general/src/chat/utils.server.js'
import { getTextPreview } from '@/general/src/other/textPreview.js'
import { getRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Table } from '@/webapp/src/lib/uninty.components.js'
import { formatDate } from 'date-fns/format'

export const ChatsTable = ({
  chats,
  loadMore,
  hasMore,
  viewerType,
}: {
  chats: ClientChat[]
  loadMore?: () => any
  hasMore?: boolean
  viewerType: 'trader' | 'merchantMember' | 'admin'
}) => {
  return (
    <Table
      className="unintyTable"
      records={chats}
      href={(record) => {
        return getRoute({
          viewerType,
          ...record,
        })
      }}
      columns={[
        { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
        { heading: 'Сделка', body: (record) => getTextPreview(record, true), width: 300 },
        {
          heading: 'Дата последнего сообщения',
          body: (record) => formatDate(record.updatedAt, 'dd.MM.yyyy HH:mm'),
          width: 150,
        },
      ]}
      loadMore={loadMore}
      hasMore={hasMore}
    />
  )
}

export const Chats = ({ viewerType }: { viewerType: 'trader' | 'merchantMember' | 'admin' }) => {
  const query =
    viewerType === 'trader'
      ? trpc.getChatsForTrader
      : viewerType === 'merchantMember'
        ? trpc.getChatsForMerchantMember
        : viewerType === 'admin'
          ? trpc.getChatsForAdmin
          : trpc.getChatsForAdmin
  const queryResult = query.useInfiniteQuery(
    {},
    {
      getNextPageParam: (lastPage) => {
        return lastPage.nextCursor
      },
      refetchOnMount: true,
    }
  )
  const chats = queryResult.data?.pages.flatMap((page) => page.chats) || []
  return (
    <>
      {queryResult.isLoading ? (
        <p>Загрузка...</p>
      ) : !chats.length ? (
        <p>Здесь пусто</p>
      ) : (
        <ChatsTable
          viewerType={viewerType}
          chats={chats}
          loadMore={() => {
            if (!queryResult.isFetchingNextPage && queryResult.hasNextPage) {
              void queryResult.fetchNextPage()
            }
          }}
          hasMore={queryResult.hasNextPage}
        />
      )}
    </>
  )
}
