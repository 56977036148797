import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { toMoney } from '@/general/src/other/money.js'
import { zPickTraderInsuranceWithdrawForAdminEndpointInput } from '@/general/src/traderInsuranceWithdraw/routes/pickTraderInsuranceWithdrawForAdmin/input.js'
import { toHumanTraderInsuranceWithdrawStatus } from '@/general/src/traderInsuranceWithdraw/utils.shared.js'
import type { AuthorizedAdminMe } from '@/webapp/src/lib/ctx.js'
import { useFormy } from '@/webapp/src/lib/formy.js'
import { adminTraderInsuranceWithdrawViewRoute } from '@/webapp/src/lib/routes.js'
import { withSectionWrapper } from '@/webapp/src/lib/sectionWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Link, Table } from '@/webapp/src/lib/uninty.components.js'
import { formatDate } from 'date-fns/format'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const TraderInsuranceWithdrawPicker = ({
  traderWithdraw,
}: {
  traderWithdraw: TrpcRouterOutput['getTraderInsuranceWithdrawsForAdmin']['traderInsuranceWithdraws'][number]
}) => {
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const pickTraderInsuranceWithdrawForAdmin = trpc.pickTraderInsuranceWithdrawForAdmin.useMutation()
  const formy = useFormy({
    initialValues: {
      traderInsuranceWithdrawId: traderWithdraw.id,
    },
    validationSchema: zPickTraderInsuranceWithdrawForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { traderInsuranceWithdraw } = await pickTraderInsuranceWithdrawForAdmin.mutateAsync(valuesInput)
      navigate(
        adminTraderInsuranceWithdrawViewRoute.get({
          traderInsuranceWithdrawSerialNumber: traderInsuranceWithdraw.serialNumber,
        })
      )
    },
    successMessage: 'Трейдерский страховой виздрав принят',
    resetOnSuccess: false,
  })
  useEffect(() => {
    if (formy.isSubmitting) {
      setSubmitting(true)
    } else {
      setSubmitting(false)
    }
  }, [formy.isSubmitting])
  return (
    <Link
      href="#"
      disabled={submitting}
      onClick={() => {
        formy.handleSubmit()
      }}
    >
      Взяться
    </Link>
  )
}

export const AvailableTraderInsuranceWithdraws = withSectionWrapper({
  forwardProps: (props: { me: AuthorizedAdminMe }) => props,
  showLoaderOnFetching: false,
  useQuery: () =>
    trpc.getTraderInsuranceWithdrawsForAdmin.useQuery(
      {
        status: 'picking',
      },
      {
        refetchInterval: 3_000,
      }
    ),
  setProps: ({ queryResult, me }) => ({
    traderInsuranceWithdraws: queryResult.data.traderInsuranceWithdraws,
    me,
  }),
})(({ traderInsuranceWithdraws }) => {
  return !traderInsuranceWithdraws.length ? (
    <p>Здесь пусто</p>
  ) : (
    <Table
      className="unintyTable"
      records={traderInsuranceWithdraws}
      columns={[
        { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
        {
          heading: 'Статус',
          body: (record) => toHumanTraderInsuranceWithdrawStatus(record.status),
          width: 150,
        },
        {
          heading: 'Дата',
          body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
          width: 150,
        },
        {
          heading: 'Сумма',
          body: (record) => toMoney({ amount: record.amount, currency: record.currency }),
          width: 100,
        },
        {
          heading: 'Сумма, ₮',
          body: (record) => toMoney({ amount: record.amountUsdt, currency: 'usdt' }),
          width: 100,
        },
        {
          heading: null,
          body: (record) => <TraderInsuranceWithdrawPicker traderWithdraw={record} />,
          width: 100,
        },
      ]}
    />
  )
})
