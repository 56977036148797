import { pageData } from './pageData.js'
import { integerWithDecimalsToAmountString, toMoney } from '@/general/src/other/money.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { adminTraderEditRoute, adminTraderNewRoute } from '@/webapp/src/lib/routes.js'
import { SectionWrapper } from '@/webapp/src/lib/sectionWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, Button, Buttons, Segment, Table } from '@/webapp/src/lib/uninty.components.js'

export const AdminTraderListPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedAdminsOnly: true,
})(() => {
  return (
    <Block fcnw>
      <Segment
        title="Трейдеры"
        size="m"
        desc={
          <Buttons>
            <Button as="a" className="buttonA" href={adminTraderNewRoute.get()}>
              Добавить трейдера
            </Button>
          </Buttons>
        }
      >
        <SectionWrapper
          useQuery={() => trpc.getTradersForAdmin.useQuery({})}
          setProps={({ queryResult }) => ({
            traders: queryResult.data.traders,
          })}
        >
          {({ traders }) => {
            return (
              <Table
                className="unintyTable"
                records={traders}
                href={(record) => adminTraderEditRoute.get({ traderSerialNumber: record.serialNumber })}
                columns={[
                  { heading: 'SN', body: (record) => record.serialNumber, width: 70 },
                  { heading: 'Имя', body: (record) => record.name, width: 200 },
                  { heading: 'Почта', body: (record) => record.email, width: 200 },
                  { heading: 'Телефон', body: (record) => record.phone, width: 200 },
                  {
                    heading: 'СБ',
                    body: (record) => toMoney({ currency: 'usdt', amount: record.balanceUsdtFree }),
                    width: 90,
                  },
                  {
                    heading: 'ЗБ',
                    body: (record) => toMoney({ currency: 'usdt', amount: record.balanceUsdtHolded }),
                    width: 90,
                  },
                  {
                    heading: 'ССБ',
                    body: (record) => toMoney({ currency: 'usdt', amount: record.balanceUsdtInsuranceFree }),
                    width: 90,
                  },
                  {
                    heading: 'СЗБ',
                    body: (record) => toMoney({ currency: 'usdt', amount: record.balanceUsdtInsuranceHolded }),
                    width: 90,
                  },
                  {
                    heading: '% КД',
                    body: (record) =>
                      integerWithDecimalsToAmountString({ amount: record.customerDepositInterestShare }),
                    width: 90,
                  },
                  {
                    heading: '% КВ',
                    body: (record) =>
                      integerWithDecimalsToAmountString({ amount: record.customerWithdrawInterestShare }),
                    width: 90,
                  },
                ]}
              />
            )
          }}
        </SectionWrapper>
      </Segment>
    </Block>
  )
})
