import { toMoney } from '@/general/src/other/money.js'
import { toHumanTraderInsuranceWithdrawStatus } from '@/general/src/traderInsuranceWithdraw/utils.shared.js'
import { traderTraderInsuranceWithdrawViewRoute } from '@/webapp/src/lib/routes.js'
import { withSectionWrapper } from '@/webapp/src/lib/sectionWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Table } from '@/webapp/src/lib/uninty.components.js'
import { formatDate } from 'date-fns/format'

export const ActiveTraderInsuranceWithdraws = withSectionWrapper({
  showLoaderOnFetching: false,
  useQuery: () =>
    trpc.getTraderInsuranceWithdrawsForTrader.useQuery(
      {
        myActive: true,
      },
      {
        refetchInterval: 3_000,
      }
    ),
  setProps: ({ queryResult }) => ({
    traderInsuranceWithdraws: queryResult.data.traderInsuranceWithdraws,
  }),
})(({ traderInsuranceWithdraws }) => {
  return !traderInsuranceWithdraws.length ? (
    <p>Здесь пусто</p>
  ) : (
    <Table
      className="unintyTable"
      records={traderInsuranceWithdraws}
      href={(record) =>
        traderTraderInsuranceWithdrawViewRoute.get({
          traderInsuranceWithdrawSerialNumber: record.serialNumber,
        })
      }
      columns={[
        { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
        {
          heading: 'Статус',
          body: (record) => toHumanTraderInsuranceWithdrawStatus(record.status),
          width: 150,
        },
        {
          heading: 'Дата',
          body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
          width: 150,
        },
        {
          heading: 'Сумма',
          body: (record) => toMoney({ amount: record.amount, currency: record.currency }),
          width: 100,
        },
        {
          heading: 'Сумма, ₮',
          body: (record) => toMoney({ amount: record.amountUsdt, currency: 'usdt' }),
          width: 100,
        },
      ]}
    />
  )
})
