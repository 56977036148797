import { pageData } from './pageData.js'
import { Chat } from '@/general/src/chat/components/Chat/index.js'
import { toMoney } from '@/general/src/other/money.js'
import { toHumanTraderInsuranceDepositStatus } from '@/general/src/traderInsuranceDeposit/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { traderTraderInsuranceDepositViewRoute } from '@/webapp/src/lib/routes.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, LabeledValues, Segment } from '@/webapp/src/lib/uninty.components.js'
import { useRouteParams } from '@/webapp/src/lib/useRoute.js'
import { formatDate } from 'date-fns/format'

export const TraderTraderInsuranceDepositViewPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedTradersOnly: true,
  useQuery: () => {
    const { routeParams } = useRouteParams(traderTraderInsuranceDepositViewRoute)
    const queryResult = trpc.getTraderInsuranceDepositForTrader.useQuery({
      traderInsuranceDepositSerialNumber: +routeParams.traderInsuranceDepositSerialNumber,
    })
    return queryResult
  },
  showLoaderOnFetching: false,
  setProps: ({ queryResult }) => ({
    traderInsuranceDeposit: queryResult.data.traderInsuranceDeposit,
  }),
})(({ traderInsuranceDeposit }) => {
  return (
    <Segment title={`Трейдерский страховой депозит #${traderInsuranceDeposit.serialNumber}`} size="m">
      <Block fcnw g={30}>
        <LabeledValues
          $style={{
            labelColor: 'var(--mantine-color-text)',
            hintColor: 'var(--mantine-color-text)',
          }}
          valuesEmptyPolicy="hide"
          items={[
            ['Статус', toHumanTraderInsuranceDepositStatus(traderInsuranceDeposit.status)],
            ['Сумма в USDT', toMoney({ amount: traderInsuranceDeposit.amountUsdt, currency: 'usdt' })],
            ['Создание', formatDate(traderInsuranceDeposit.createdAt, 'dd.MM.yyyy HH:mm')],
          ]}
        />
      </Block>
      <Chat chatId={traderInsuranceDeposit.chatId} viewerType="trader" />
    </Segment>
  )
})
