import { toHumanMerchantDepositStatus } from '@/general/src/merchantDeposit/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import { adminMerchantDepositViewRoute } from '@/webapp/src/lib/routes.js'
import { withSectionWrapper } from '@/webapp/src/lib/sectionWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Table } from '@/webapp/src/lib/uninty.components.js'
import { formatDate } from 'date-fns/format'

export const ActiveMerchantDeposits = withSectionWrapper({
  showLoaderOnFetching: false,
  useQuery: () =>
    trpc.getMerchantDepositsForAdmin.useQuery(
      {
        myActive: true,
      },
      {
        refetchInterval: 3_000,
      }
    ),
  setProps: ({ queryResult }) => ({
    merchantDeposits: queryResult.data.merchantDeposits,
  }),
})(({ merchantDeposits }) => {
  return !merchantDeposits.length ? (
    <p>Здесь пусто</p>
  ) : (
    <Table
      className="unintyTable"
      records={merchantDeposits}
      href={(record) =>
        adminMerchantDepositViewRoute.get({
          merchantDepositSerialNumber: record.serialNumber,
        })
      }
      columns={[
        { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
        {
          heading: 'Статус',
          body: (record) => toHumanMerchantDepositStatus(record.status),
          width: 200,
        },
        {
          heading: 'Мерчант',
          body: (record) => `#${record.merchant.serialNumber}`,
          width: 150,
        },
        {
          heading: 'Дата',
          body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
          width: 150,
        },
        {
          heading: 'Сумма',
          body: (record) => toMoney({ amount: record.amount, currency: record.currency }),
          width: 100,
        },
        {
          heading: 'Сумма, ₮',
          body: (record) => toMoney({ amount: record.amountUsdt, currency: 'usdt' }),
          width: 100,
        },
      ]}
    />
  )
})
