import { getOneSharedEnv } from '@/general/src/other/sharedEnv.js'
import { createRoutyThings } from 'svag-routy'

const { createRoute } = createRoutyThings({
  baseUrl: getOneSharedEnv('WEBAPP_URL'),
  definitionParamsPrefix: ':',
})

// General

export const testRoute = createRoute(`/test`)
export const demoRoute = createRoute(`/demo`)
export const demoSuccessRoute = demoRoute.createRoute(`/success`)
export const demoFailRoute = demoRoute.createRoute(`/fail`)

export const homeRoute = createRoute(`/`)
export const docsRoute = homeRoute.createRoute(`/docs`)
export const appRoute = homeRoute.createRoute(`/app`)

export const merchantCustomerRoute = homeRoute.createRoute(`/customer`)
export const merchantRoute = appRoute.createRoute(`/merchant`)
export const traderRoute = appRoute.createRoute(`/trader`)
export const adminRoute = appRoute.createRoute(`/admin`)

// Docs

export const policyRoute = docsRoute.createRoute(`/policy`)
export const termsRoute = docsRoute.createRoute(`/terms`)
export const apiDocsRoute = docsRoute.createRoute(`/api`)
export const webhooksDocsRoute = docsRoute.createRoute(`/webhooks`)
export const generalDocsRoute = docsRoute.createRoute(`/general`)

// Auth

export const merchantAuthRoute = merchantRoute.createRoute(`/auth`)
export const merchantSignInRoute = merchantAuthRoute.createRoute(`/sign-in`)
export const merchantSignUpRoute = merchantAuthRoute.createRoute(`/sign-up`)
export const merchantSignOutRoute = merchantAuthRoute.createRoute(`/sign-out`)
export const merchantRestorePasswordRoute = merchantAuthRoute.createRoute(`/restore-password`)
export const merchantResetPasswordRoute = merchantAuthRoute.createRoute(`/reset-password`)

export const traderAuthRoute = traderRoute.createRoute(`/auth`)
export const traderSignInRoute = traderAuthRoute.createRoute(`/sign-in`)
export const traderSignUpRoute = traderAuthRoute.createRoute(`/sign-up`)
export const traderSignOutRoute = traderAuthRoute.createRoute(`/sign-out`)
export const traderRestorePasswordRoute = traderAuthRoute.createRoute(`/restore-password`)
export const traderResetPasswordRoute = traderAuthRoute.createRoute(`/reset-password`)

export const adminAuthRoute = adminRoute.createRoute(`/auth`)
export const adminSignInRoute = adminAuthRoute.createRoute(`/sign-in`)
export const adminSignUpRoute = adminAuthRoute.createRoute(`/sign-up`)
export const adminSignOutRoute = adminAuthRoute.createRoute(`/sign-out`)
export const adminRestorePasswordRoute = adminAuthRoute.createRoute(`/restore-password`)
export const adminResetPasswordRoute = adminAuthRoute.createRoute(`/reset-password`)

// Trader

export const traderDashboardRoute = traderRoute.createRoute(`/dashboard`)
export const traderSettingsRoute = traderRoute.createRoute(`/settings`)
export const traderTxsRoute = traderRoute.createRoute(`/transactions`)
export const traderChatsRoute = traderRoute.createRoute(`/chats`)
export const traderAutomatorRoute = traderRoute.createRoute(`/automator`)

export const traderCustomerWithdrawRoute = traderRoute.createRoute(`/customer-withdraw`)
export const traderCustomerWithdrawListRoute = traderCustomerWithdrawRoute.createRoute(`/list`)
export const traderCustomerWithdrawViewRoute = traderCustomerWithdrawRoute.createRoute({
  params: ['customerWithdrawSerialNumber'],
  getter: ({ customerWithdrawSerialNumber }) => `/view/${customerWithdrawSerialNumber}`,
})

export const traderCustomerDepositRoute = traderRoute.createRoute(`/customer-deposit`)
export const traderCustomerDepositListRoute = traderCustomerDepositRoute.createRoute(`/list`)
export const traderCustomerDepositViewRoute = traderCustomerDepositRoute.createRoute({
  params: ['customerDepositSerialNumber'],
  getter: ({ customerDepositSerialNumber }) => `/view/${customerDepositSerialNumber}`,
})

export const traderTraderWithdrawRoute = traderRoute.createRoute(`/trader-withdraw`)
export const traderTraderWithdrawListRoute = traderTraderWithdrawRoute.createRoute(`/list`)
export const traderTraderWithdrawViewRoute = traderTraderWithdrawRoute.createRoute({
  params: ['traderWithdrawSerialNumber'],
  getter: ({ traderWithdrawSerialNumber }) => `/view/${traderWithdrawSerialNumber}`,
})
export const traderTraderDepositRoute = traderRoute.createRoute(`/trader-deposit`)
export const traderTraderDepositListRoute = traderTraderDepositRoute.createRoute(`/list`)
export const traderTraderDepositNewRoute = traderTraderDepositRoute.createRoute(`/new`)
export const traderTraderDepositViewRoute = traderTraderDepositRoute.createRoute({
  params: ['traderDepositSerialNumber'],
  getter: ({ traderDepositSerialNumber }) => `/view/${traderDepositSerialNumber}`,
})

export const traderTraderInsuranceWithdrawRoute = traderRoute.createRoute(`/trader-insurance-withdraw`)
export const traderTraderInsuranceWithdrawListRoute = traderTraderInsuranceWithdrawRoute.createRoute(`/list`)
export const traderTraderInsuranceWithdrawNewRoute = traderTraderInsuranceWithdrawRoute.createRoute(`/new`)
export const traderTraderInsuranceWithdrawViewRoute = traderTraderInsuranceWithdrawRoute.createRoute({
  params: ['traderInsuranceWithdrawSerialNumber'],
  getter: ({ traderInsuranceWithdrawSerialNumber }) => `/view/${traderInsuranceWithdrawSerialNumber}`,
})

export const traderTraderInsuranceDepositRoute = traderRoute.createRoute(`/trader-insurance-deposit`)
export const traderTraderInsuranceDepositListRoute = traderTraderInsuranceDepositRoute.createRoute(`/list`)
export const traderTraderInsuranceDepositNewRoute = traderTraderInsuranceDepositRoute.createRoute(`/new`)
export const traderTraderInsuranceDepositViewRoute = traderTraderInsuranceDepositRoute.createRoute({
  params: ['traderInsuranceDepositSerialNumber'],
  getter: ({ traderInsuranceDepositSerialNumber }) => `/view/${traderInsuranceDepositSerialNumber}`,
})

// Merchant member

export const merchantDashboardRoute = merchantRoute.createRoute(`/dashboard`)
export const merchantSettingsRoute = merchantRoute.createRoute(`/merchant-settings`)
export const merchantMemberSettingsRoute = merchantRoute.createRoute(`/my-settings`)
export const merchantApiRoute = merchantRoute.createRoute(`/api`)
export const merchantWebhooksRoute = merchantRoute.createRoute(`/webhooks`)
export const merchantTxsRoute = merchantRoute.createRoute(`/transactions`)
export const merchantChatsRoute = merchantRoute.createRoute(`/chats`)

export const merchantCustomerWithdrawRoute = merchantRoute.createRoute(`/customer-withdraw`)
export const merchantCustomerWithdrawListRoute = merchantCustomerWithdrawRoute.createRoute(`/list`)
export const merchantCustomerWithdrawViewRoute = merchantCustomerWithdrawRoute.createRoute({
  params: ['customerWithdrawSerialNumber'],
  getter: ({ customerWithdrawSerialNumber }) => `/view/${customerWithdrawSerialNumber}`,
})

export const merchantCustomerDepositRoute = merchantRoute.createRoute(`/customer-deposit`)
export const merchantCustomerDepositListRoute = merchantCustomerDepositRoute.createRoute(`/list`)
export const merchantCustomerDepositViewRoute = merchantCustomerDepositRoute.createRoute({
  params: ['customerDepositSerialNumber'],
  getter: ({ customerDepositSerialNumber }) => `/view/${customerDepositSerialNumber}`,
})

export const merchantMerchantWithdrawRoute = merchantRoute.createRoute(`/merchant-withdraw`)
export const merchantMerchantWithdrawListRoute = merchantMerchantWithdrawRoute.createRoute(`/list`)
export const merchantMerchantWithdrawViewRoute = merchantMerchantWithdrawRoute.createRoute({
  params: ['merchantWithdrawSerialNumber'],
  getter: ({ merchantWithdrawSerialNumber }) => `/view/${merchantWithdrawSerialNumber}`,
})
export const merchantMerchantWithdrawNewRoute = merchantMerchantWithdrawRoute.createRoute(`/new`)

export const merchantMerchantDepositRoute = merchantRoute.createRoute(`/merchant-deposit`)
export const merchantMerchantDepositListRoute = merchantMerchantDepositRoute.createRoute(`/list`)
export const merchantMerchantDepositViewRoute = merchantMerchantDepositRoute.createRoute({
  params: ['merchantDepositSerialNumber'],
  getter: ({ merchantDepositSerialNumber }) => `/view/${merchantDepositSerialNumber}`,
})
export const merchantMerchantDepositNewRoute = merchantMerchantDepositRoute.createRoute(`/new`)

export const merchantMerchantMemberRoute = merchantRoute.createRoute(`/member`)
export const merchantMerchantMemberListRoute = merchantMerchantMemberRoute.createRoute(`/list`)
export const merchantMerchantMemberViewRoute = merchantMerchantMemberRoute.createRoute({
  params: ['merchantMemberSerialNumber'],
  getter: ({ merchantMemberSerialNumber }) => `/view/${merchantMemberSerialNumber}`,
})
export const merchantMerchantMemberEditRoute = merchantMerchantMemberRoute.createRoute({
  params: ['merchantMemberSerialNumber'],
  getter: ({ merchantMemberSerialNumber }) => `/edit/${merchantMemberSerialNumber}`,
})
export const merchantMerchantMemberNewRoute = merchantMerchantMemberRoute.createRoute(`/new`)

// Merchant customer

export const merchantCustomerCustomerWithdrawRoute = merchantCustomerRoute.createRoute(`/withdraw`)
export const merchantCustomerCustomerWithdrawViewRoute = merchantCustomerCustomerWithdrawRoute.createRoute({
  params: ['customerWithdrawId', 'customerWithdrawSecret'],
  getter: ({ customerWithdrawId, customerWithdrawSecret }) => `/view/${customerWithdrawId}/${customerWithdrawSecret}`,
})

export const merchantCustomerCustomerDepositRoute = merchantCustomerRoute.createRoute(`/deposit`)
export const merchantCustomerCustomerDepositViewRoute = merchantCustomerCustomerDepositRoute.createRoute({
  params: ['customerDepositId', 'customerDepositSecret'],
  getter: ({ customerDepositId, customerDepositSecret }) => `/${customerDepositId}/${customerDepositSecret}`,
})

// Admin

export const adminDashboardRoute = adminRoute.createRoute(`/dashboard`)
export const adminSettingsRoute = adminRoute.createRoute(`/my-settings`)
export const adminCompanySettingsRoute = adminRoute.createRoute(`/company-settings`)
export const adminTxsRoute = adminRoute.createRoute(`/transactions`)
export const adminChatsRoute = adminRoute.createRoute(`/chats`)
export const adminActionLogsRoute = adminRoute.createRoute(`/action-logs`)

export const adminCustomerWithdrawRoute = adminRoute.createRoute(`/customer-withdraw`)
export const adminCustomerWithdrawListRoute = adminCustomerWithdrawRoute.createRoute(`/list`)
export const adminCustomerWithdrawViewRoute = adminCustomerWithdrawRoute.createRoute({
  params: ['customerWithdrawSerialNumber'],
  getter: ({ customerWithdrawSerialNumber }) => `/view/${customerWithdrawSerialNumber}`,
})

export const adminCustomerDepositRoute = adminRoute.createRoute(`/customer-deposit`)
export const adminCustomerDepositListRoute = adminCustomerDepositRoute.createRoute(`/list`)
export const adminCustomerDepositViewRoute = adminCustomerDepositRoute.createRoute({
  params: ['customerDepositSerialNumber'],
  getter: ({ customerDepositSerialNumber }) => `/view/${customerDepositSerialNumber}`,
})

export const adminMerchantWithdrawRoute = adminRoute.createRoute(`/merchant-withdraw`)
export const adminMerchantWithdrawListRoute = adminMerchantWithdrawRoute.createRoute(`/list`)
export const adminMerchantWithdrawViewRoute = adminMerchantWithdrawRoute.createRoute({
  params: ['merchantWithdrawSerialNumber'],
  getter: ({ merchantWithdrawSerialNumber }) => `/view/${merchantWithdrawSerialNumber}`,
})
export const adminMerchantDepositRoute = adminRoute.createRoute(`/merchant-deposit`)
export const adminMerchantDepositListRoute = adminMerchantDepositRoute.createRoute(`/list`)
export const adminMerchantDepositViewRoute = adminMerchantDepositRoute.createRoute({
  params: ['merchantDepositSerialNumber'],
  getter: ({ merchantDepositSerialNumber }) => `/view/${merchantDepositSerialNumber}`,
})

export const adminTraderWithdrawRoute = adminRoute.createRoute(`/trader-withdraw`)
export const adminTraderWithdrawListRoute = adminTraderWithdrawRoute.createRoute(`/list`)
export const adminTraderWithdrawViewRoute = adminTraderWithdrawRoute.createRoute({
  params: ['traderWithdrawSerialNumber'],
  getter: ({ traderWithdrawSerialNumber }) => `/view/${traderWithdrawSerialNumber}`,
})
export const adminTraderDepositRoute = adminRoute.createRoute(`/trader-deposit`)
export const adminTraderDepositListRoute = adminTraderDepositRoute.createRoute(`/list`)
export const adminTraderDepositViewRoute = adminTraderDepositRoute.createRoute({
  params: ['traderDepositSerialNumber'],
  getter: ({ traderDepositSerialNumber }) => `/view/${traderDepositSerialNumber}`,
})

export const adminTraderInsuranceWithdrawRoute = adminRoute.createRoute(`/trader-insurance-withdraw`)
export const adminTraderInsuranceWithdrawListRoute = adminTraderInsuranceWithdrawRoute.createRoute(`/list`)
export const adminTraderInsuranceWithdrawViewRoute = adminTraderInsuranceWithdrawRoute.createRoute({
  params: ['traderInsuranceWithdrawSerialNumber'],
  getter: ({ traderInsuranceWithdrawSerialNumber }) => `/view/${traderInsuranceWithdrawSerialNumber}`,
})
export const adminTraderInsuranceDepositRoute = adminRoute.createRoute(`/trader-insurance-deposit`)
export const adminTraderInsuranceDepositListRoute = adminTraderInsuranceDepositRoute.createRoute(`/list`)
export const adminTraderInsuranceDepositViewRoute = adminTraderInsuranceDepositRoute.createRoute({
  params: ['traderInsuranceDepositSerialNumber'],
  getter: ({ traderInsuranceDepositSerialNumber }) => `/view/${traderInsuranceDepositSerialNumber}`,
})

export const adminMerchantRoute = adminRoute.createRoute(`/merchant`)
export const adminMerchantListRoute = adminMerchantRoute.createRoute(`/list`)
export const adminMerchantViewRoute = adminMerchantRoute.createRoute({
  params: ['merchantSerialNumber'],
  getter: ({ merchantSerialNumber }) => `/view/${merchantSerialNumber}`,
})
export const adminMerchantEditRoute = adminMerchantRoute.createRoute({
  params: ['merchantSerialNumber'],
  getter: ({ merchantSerialNumber }) => `/edit/${merchantSerialNumber}`,
})
export const adminMerchantNewRoute = adminMerchantRoute.createRoute(`/new`)

export const adminMerchantMemberRoute = adminMerchantRoute.createRoute({
  params: ['merchantSerialNumber'],
  getter: ({ merchantSerialNumber }) => `/member/${merchantSerialNumber}`,
})
export const adminMerchantMemberListRoute = adminMerchantMemberRoute.createRoute(`/list`)
export const adminMerchantMemberViewRoute = adminMerchantMemberRoute.createRoute({
  params: ['merchantMemberSerialNumber'],
  getter: ({ merchantMemberSerialNumber }) => `/view/${merchantMemberSerialNumber}`,
})
export const adminMerchantMemberEditRoute = adminMerchantMemberRoute.createRoute({
  params: ['merchantMemberSerialNumber'],
  getter: ({ merchantMemberSerialNumber }) => `/edit/${merchantMemberSerialNumber}`,
})
export const adminMerchantMemberNewRoute = adminMerchantMemberRoute.createRoute(`/new`)

export const adminTraderRoute = adminRoute.createRoute(`/trader`)
export const adminTraderListRoute = adminTraderRoute.createRoute(`/list`)
export const adminTraderViewRoute = adminTraderRoute.createRoute({
  params: ['traderSerialNumber'],
  getter: ({ traderSerialNumber }) => `/view/${traderSerialNumber}`,
})
export const adminTraderEditRoute = adminTraderRoute.createRoute({
  params: ['traderSerialNumber'],
  getter: ({ traderSerialNumber }) => `/edit/${traderSerialNumber}`,
})
export const adminTraderNewRoute = adminTraderRoute.createRoute(`/new`)

export const adminAdminRoute = adminRoute.createRoute(`/admin`)
export const adminAdminListRoute = adminAdminRoute.createRoute(`/list`)
export const adminAdminViewRoute = adminAdminRoute.createRoute({
  params: ['adminSerialNumber'],
  getter: ({ adminSerialNumber }) => `/view/${adminSerialNumber}`,
})
export const adminAdminEditRoute = adminAdminRoute.createRoute({
  params: ['adminSerialNumber'],
  getter: ({ adminSerialNumber }) => `/edit/${adminSerialNumber}`,
})
export const adminAdminNewRoute = adminAdminRoute.createRoute(`/new`)

// specific

type WithSerialNumberOrNull = { serialNumber: number } | null
export const getRoute = ({
  viewerType,
  abs,
  customerWithdraw,
  customerDeposit,
  merchantWithdraw,
  merchantDeposit,
  traderWithdraw,
  traderDeposit,
  traderInsuranceWithdraw,
  traderInsuranceDeposit,
  trader,
  // admin,
  merchant,
  merchantMember,
}: {
  viewerType: 'trader' | 'merchantMember' | 'admin'
  abs?: boolean
  customerWithdraw?: WithSerialNumberOrNull
  customerDeposit?: WithSerialNumberOrNull
  merchantWithdraw?: WithSerialNumberOrNull
  merchantDeposit?: WithSerialNumberOrNull
  traderWithdraw?: WithSerialNumberOrNull
  traderDeposit?: WithSerialNumberOrNull
  traderInsuranceWithdraw?: WithSerialNumberOrNull
  traderInsuranceDeposit?: WithSerialNumberOrNull
  trader?: WithSerialNumberOrNull
  merchant?: WithSerialNumberOrNull
  admin?: WithSerialNumberOrNull
  merchantMember?: { serialNumber: number; merchantSerialNumber: number } | null
}) => {
  if (viewerType === 'admin') {
    if (customerWithdraw) {
      return adminCustomerWithdrawViewRoute.get({ customerWithdrawSerialNumber: customerWithdraw.serialNumber, abs })
    }
    if (customerDeposit) {
      return adminCustomerDepositViewRoute.get({ customerDepositSerialNumber: customerDeposit.serialNumber, abs })
    }
    if (merchantWithdraw) {
      return adminMerchantWithdrawViewRoute.get({ merchantWithdrawSerialNumber: merchantWithdraw.serialNumber, abs })
    }
    if (merchantDeposit) {
      return adminMerchantDepositViewRoute.get({ merchantDepositSerialNumber: merchantDeposit.serialNumber, abs })
    }
    if (traderWithdraw) {
      return adminTraderWithdrawViewRoute.get({ traderWithdrawSerialNumber: traderWithdraw.serialNumber, abs })
    }
    if (traderDeposit) {
      return adminTraderDepositViewRoute.get({ traderDepositSerialNumber: traderDeposit.serialNumber, abs })
    }
    if (traderInsuranceWithdraw) {
      return adminTraderInsuranceWithdrawViewRoute.get({
        traderInsuranceWithdrawSerialNumber: traderInsuranceWithdraw.serialNumber,
        abs,
      })
    }
    if (traderInsuranceDeposit) {
      return adminTraderInsuranceDepositViewRoute.get({
        traderInsuranceDepositSerialNumber: traderInsuranceDeposit.serialNumber,
        abs,
      })
    }
    if (trader) {
      // return adminTraderViewRoute.get({ traderSerialNumber: trader.serialNumber, abs })
      return adminTraderEditRoute.get({ traderSerialNumber: trader.serialNumber, abs })
    }
    if (merchant) {
      // return adminMerchantViewRoute.get({ merchantSerialNumber: merchant.serialNumber, abs })
      return adminMerchantEditRoute.get({ merchantSerialNumber: merchant.serialNumber, abs })
    }
    if (merchantMember) {
      // return adminMerchantMemberViewRoute.get({
      //   merchantMemberSerialNumber: merchantMember.serialNumber,
      //   merchantSerialNumber: merchantMember.merchantSerialNumber,
      //   abs,
      // })
      return adminMerchantMemberEditRoute.get({
        merchantMemberSerialNumber: merchantMember.serialNumber,
        merchantSerialNumber: merchantMember.merchantSerialNumber,
        abs,
      })
    }
    // if (admin) {
    //   return adminRoute.get()
    // }
  }
  if (viewerType === 'merchantMember') {
    if (customerWithdraw) {
      return merchantCustomerWithdrawViewRoute.get({ customerWithdrawSerialNumber: customerWithdraw.serialNumber, abs })
    }
    if (customerDeposit) {
      return merchantCustomerDepositViewRoute.get({ customerDepositSerialNumber: customerDeposit.serialNumber, abs })
    }
    if (merchantWithdraw) {
      return merchantMerchantWithdrawViewRoute.get({ merchantWithdrawSerialNumber: merchantWithdraw.serialNumber, abs })
    }
    if (merchantDeposit) {
      return merchantMerchantDepositViewRoute.get({ merchantDepositSerialNumber: merchantDeposit.serialNumber, abs })
    }
    if (merchantMember) {
      return merchantMerchantMemberViewRoute.get({ merchantMemberSerialNumber: merchantMember.serialNumber, abs })
    }
  }
  if (viewerType === 'trader') {
    if (customerWithdraw) {
      return traderCustomerWithdrawViewRoute.get({ customerWithdrawSerialNumber: customerWithdraw.serialNumber, abs })
    }
    if (customerDeposit) {
      return traderCustomerDepositViewRoute.get({ customerDepositSerialNumber: customerDeposit.serialNumber, abs })
    }
    if (traderWithdraw) {
      return traderTraderWithdrawViewRoute.get({ traderWithdrawSerialNumber: traderWithdraw.serialNumber, abs })
    }
    if (traderDeposit) {
      return traderTraderDepositViewRoute.get({ traderDepositSerialNumber: traderDeposit.serialNumber, abs })
    }
    if (traderInsuranceWithdraw) {
      return traderTraderInsuranceWithdrawViewRoute.get({
        traderInsuranceWithdrawSerialNumber: traderInsuranceWithdraw.serialNumber,
        abs,
      })
    }
    if (traderInsuranceDeposit) {
      return traderTraderInsuranceDepositViewRoute.get({
        traderInsuranceDepositSerialNumber: traderInsuranceDeposit.serialNumber,
        abs,
      })
    }
  }
  return undefined
}
