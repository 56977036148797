import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { zPickCustomerDepositForTraderEndpointInput } from '@/general/src/customerDeposit/routes/pickCustomerDepositForTrader/input.js'
import { toHumanCustomerDepositStatus } from '@/general/src/customerDeposit/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import type { ClientPaymentMethodForOwner } from '@/general/src/paymentMethod/utils.server.js'
import {
  filterPaymentMethodsByGroupAndType,
  toHumanPaymentMethodType,
  toHumanReadablePaymentMethod,
} from '@/general/src/paymentMethod/utils.shared.js'
import type { AuthorizedTraderMe } from '@/webapp/src/lib/ctx.js'
import { useFormy } from '@/webapp/src/lib/formy.js'
import { traderCustomerDepositViewRoute } from '@/webapp/src/lib/routes.js'
import { withSectionWrapper } from '@/webapp/src/lib/sectionWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Card, ContextMenuItem, Link, Table, WithPopover } from '@/webapp/src/lib/uninty.components.js'
import { formatDate } from 'date-fns/format'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const CustomerDepositPickerItem = ({
  customerDeposit,
  paymentMethod,
  setSubmitting,
}: {
  customerDeposit: TrpcRouterOutput['getCustomerDepositsForTrader']['customerDeposits'][number]
  paymentMethod: ClientPaymentMethodForOwner
  setSubmitting: (submitting: boolean) => void
}) => {
  const navigate = useNavigate()
  const pickCustomerDepositForTrader = trpc.pickCustomerDepositForTrader.useMutation()
  const formy = useFormy({
    initialValues: {
      paymentMethodId: paymentMethod.id,
      customerDepositId: customerDeposit.id,
    },
    validationSchema: zPickCustomerDepositForTraderEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { customerDeposit } = await pickCustomerDepositForTrader.mutateAsync(valuesInput)
      navigate(traderCustomerDepositViewRoute.get({ customerDepositSerialNumber: customerDeposit.serialNumber }))
    },
    successMessage: 'Клиентский депозит принят',
    resetOnSuccess: false,
  })
  useEffect(() => {
    if (formy.isSubmitting) {
      setSubmitting(true)
    } else {
      setSubmitting(false)
    }
  }, [formy.isSubmitting])
  return (
    <ContextMenuItem
      onClick={() => {
        formy.handleSubmit()
      }}
    >
      {toHumanReadablePaymentMethod(paymentMethod)}
    </ContextMenuItem>
  )
}

const CustomerDepositPicker = ({
  customerDeposit,
  me,
}: {
  customerDeposit: TrpcRouterOutput['getCustomerDepositsForTrader']['customerDeposits'][number]
  me: AuthorizedTraderMe
}) => {
  const [submitting, setSubmitting] = useState(false)
  const paymentMethods = filterPaymentMethodsByGroupAndType(
    me.trader.paymentMethods,
    customerDeposit.paymentMethodGroup,
    customerDeposit.paymentMethodType,
    'input'
  )

  return (
    <WithPopover
      popover={
        <Card>
          {!paymentMethods.length ? (
            <p>У вас нет подоходящего платёжного метода</p>
          ) : (
            <>
              <div style={{ display: submitting ? 'block' : 'none' }}>Загрузка...</div>
              <div style={{ display: submitting ? 'none' : 'block' }}>
                {paymentMethods.map((paymentMethod, i) => (
                  <CustomerDepositPickerItem
                    key={i}
                    customerDeposit={customerDeposit}
                    paymentMethod={paymentMethod}
                    setSubmitting={setSubmitting}
                  />
                ))}
              </div>
            </>
          )}
        </Card>
      }
    >
      <Link href="#" onClick={(e) => e.preventDefault()}>
        Взяться
      </Link>
    </WithPopover>
  )
}

export const AvailableCustomerDeposits = withSectionWrapper({
  forwardProps: (props: { me: AuthorizedTraderMe }) => props,
  showLoaderOnFetching: false,
  useQuery: () =>
    trpc.getCustomerDepositsForTrader.useQuery(
      {
        available: true,
      },
      {
        refetchInterval: 3_000,
      }
    ),
  setProps: ({ queryResult, me }) => ({
    customerDeposits: queryResult.data.customerDeposits,
    me,
  }),
})(({ customerDeposits, me }) => {
  return !customerDeposits.length ? (
    <p>Здесь пусто</p>
  ) : (
    <Table
      className="unintyTable"
      records={customerDeposits}
      columns={[
        { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
        { heading: 'Статус', body: (record) => toHumanCustomerDepositStatus(record.status), width: 150 },
        {
          heading: 'Дата',
          body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
          width: 150,
        },
        {
          heading: 'Метод',
          body: (record) => toHumanPaymentMethodType(record.paymentMethodType),
          width: 200,
        },
        {
          heading: 'Сумма',
          body: (record) => toMoney({ amount: record.dealAmount, currency: record.dealCurrency }),
          width: 100,
        },
        {
          heading: 'Сумма, ₮',
          body: (record) => toMoney({ amount: record.amountUsdt, currency: 'usdt' }),
          width: 100,
        },
        {
          heading: null,
          body: (record) => <CustomerDepositPicker customerDeposit={record} me={me} />,
          width: 100,
        },
      ]}
    />
  )
})
