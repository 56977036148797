import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { zPickMerchantDepositForAdminEndpointInput } from '@/general/src/merchantDeposit/routes/pickMerchantDepositForAdmin/input.js'
import { toHumanMerchantDepositStatus } from '@/general/src/merchantDeposit/utils.shared.js'
import { toMoney } from '@/general/src/other/money.js'
import type { PaymentMethodInternalFull } from '@/general/src/paymentMethod/utils.shared.js'
import { filterPaymentMethodsByFamily, toHumanReadablePaymentMethod } from '@/general/src/paymentMethod/utils.shared.js'
import type { AuthorizedAdminMe } from '@/webapp/src/lib/ctx.js'
import { useFormy } from '@/webapp/src/lib/formy.js'
import { adminMerchantDepositViewRoute } from '@/webapp/src/lib/routes.js'
import { withSectionWrapper } from '@/webapp/src/lib/sectionWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Card, ContextMenuItem, Link, Table, WithPopover } from '@/webapp/src/lib/uninty.components.js'
import { formatDate } from 'date-fns/format'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const MerchantDepositPickerItem = ({
  merchantDeposit,
  paymentMethod,
  setSubmitting,
}: {
  merchantDeposit: TrpcRouterOutput['getMerchantDepositsForAdmin']['merchantDeposits'][number]
  paymentMethod: PaymentMethodInternalFull
  setSubmitting: (submitting: boolean) => void
}) => {
  const navigate = useNavigate()
  const pickMerchantDepositForAdmin = trpc.pickMerchantDepositForAdmin.useMutation()
  const formy = useFormy({
    initialValues: {
      paymentMethodId: paymentMethod.id,
      merchantDepositId: merchantDeposit.id,
    },
    validationSchema: zPickMerchantDepositForAdminEndpointInput,
    onSubmit: async ({ valuesInput }) => {
      const { merchantDeposit } = await pickMerchantDepositForAdmin.mutateAsync(valuesInput)
      navigate(adminMerchantDepositViewRoute.get({ merchantDepositSerialNumber: merchantDeposit.serialNumber }))
    },
    successMessage: 'Мерчантский депозит принят',
    resetOnSuccess: false,
  })
  useEffect(() => {
    if (formy.isSubmitting) {
      setSubmitting(true)
    } else {
      setSubmitting(false)
    }
  }, [formy.isSubmitting])
  return (
    <ContextMenuItem
      onClick={() => {
        formy.handleSubmit()
      }}
    >
      {toHumanReadablePaymentMethod(paymentMethod)}
    </ContextMenuItem>
  )
}

const MerchantDepositPicker = ({
  merchantDeposit,
  me,
}: {
  merchantDeposit: TrpcRouterOutput['getMerchantDepositsForAdmin']['merchantDeposits'][number]
  me: AuthorizedAdminMe
}) => {
  const [submitting, setSubmitting] = useState(false)
  const paymentMethods = filterPaymentMethodsByFamily(me.admin.company.paymentMethods, 'internal', 'input')

  return (
    <WithPopover
      popover={
        <Card>
          <div style={{ display: submitting ? 'block' : 'none' }}>Загрузка...</div>
          <div style={{ display: submitting ? 'none' : 'block' }}>
            {paymentMethods.map((paymentMethod, i) => (
              <MerchantDepositPickerItem
                key={i}
                merchantDeposit={merchantDeposit}
                paymentMethod={paymentMethod}
                setSubmitting={setSubmitting}
              />
            ))}
          </div>
        </Card>
      }
    >
      <Link href="#" onClick={(e) => e.preventDefault()}>
        Взяться
      </Link>
    </WithPopover>
  )
}

export const AvailableMerchantDeposits = withSectionWrapper({
  forwardProps: (props: { me: AuthorizedAdminMe }) => props,
  showLoaderOnFetching: false,
  useQuery: () =>
    trpc.getMerchantDepositsForAdmin.useQuery(
      {
        status: 'picking',
      },
      {
        refetchInterval: 3_000,
      }
    ),
  setProps: ({ queryResult, me }) => ({
    merchantDeposits: queryResult.data.merchantDeposits,
    me,
  }),
})(({ merchantDeposits, me }) => {
  return !merchantDeposits.length ? (
    <p>Здесь пусто</p>
  ) : (
    <Table
      className="unintyTable"
      records={merchantDeposits}
      columns={[
        { heading: 'SN', body: (record) => record.serialNumber, width: 100 },
        {
          heading: 'Статус',
          body: (record) => toHumanMerchantDepositStatus(record.status),
          width: 150,
        },
        {
          heading: 'Мерчант',
          body: (record) => `#${record.merchant.serialNumber}`,
          width: 150,
        },
        {
          heading: 'Дата',
          body: (record) => formatDate(record.createdAt, 'dd.MM.yyyy HH:mm'),
          width: 150,
        },
        {
          heading: 'Сумма',
          body: (record) => toMoney({ amount: record.amount, currency: record.currency }),
          width: 100,
        },
        {
          heading: 'Сумма, ₮',
          body: (record) => toMoney({ amount: record.amountUsdt, currency: 'usdt' }),
          width: 100,
        },
        {
          heading: null,
          body: (record) => <MerchantDepositPicker me={me} merchantDeposit={record} />,
          width: 100,
        },
      ]}
    />
  )
})
