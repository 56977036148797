import { pageData } from './pageData.js'
import { toHumanTxType } from '@/general/src/tx/utils.shared.js'
import { toHumanWebhookEventName } from '@/general/src/webhook/utils.shared.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { RichText } from '@/webapp/src/components/other/RichText/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { Segment } from '@/webapp/src/lib/uninty.components.js'

export const GeneralDocsPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
})(() => {
  return (
    <Segment title="Документация по сути" size="m">
      <RichText>
        <h2>Мерчант</h2>
        <p>
          Мерчант — это условный интернт-магазин, который хочет принимать оплаты на своём сайте от своих клиентов или
          выводить деньги с балансов свох клиентов на своём сайте через нашу платформу.
        </p>
        <p>
          Для начала работы необходимо согласовать размер комиссий на ввод и вывод денег с менеджером платформы и
          догвориться о создании мерчанта в нашей платформе. После чего мерчант будет добавлен в платформу, а также
          будут созданы личные кабинеты для всех заинтересованных сотрудников мерчанта.
        </p>
        <p>
          Для того, чтобы произвести процесс оплаты (пополнениф счёта) на сайте мечранта для клиента мерчанта необходимо
          программными средствами на стороне сайта мерчанта создать апи запрос на создание клиентского депозита в нашей
          плфторме. Клиентский депозит можно создать вне зависимости ни от чего в любой момент времени.
        </p>
        <p>
          Для того, чтобы произвести процесс вывода средств с сайта мечранта для клиента мерчанта необходимо
          программными средствами на стороне сайта мерчанта создать апи запрос на создание клиентского виздрава в нашей
          плфторме. Клиентский виздрав можно создать только при условии, если на свободном счету мерчанта есть
          достаточная сумма: сумма клиентского виздрава (тело) + сумма комиссии (комиссия).
        </p>
        <p>
          Чтобы поплнить свой свободный баланс мерчант може создать мерчантский депозит, который обработает админ, чтобы
          перевести деньги в USDT на кошелёк платформы. Либо создавать клиентские депозиты, и тем самым естественным
          образом увеличить свой свободный баланс.
        </p>
        <p>
          На все важные события в нашей платформе (успешное исполнение клиентского виздрава, кспешное исполнение
          клиентского депозита, и т.д.) наша платформа автоматически отправляет данные по указанному вебхуку. Вы должны
          программно реагировать на эти события и в своей базе данных пополнять и скоращать внутренний баланс вашего
          клиента.
        </p>
        <p>
          Документацию по использованию апи и вебхуков вы найдёте в соответсвующих разделах документации. Настройте все
          процессы с использованием апи ключей и вебхуков в нашем стейдж окружении https://stage.payhub.one (все сделки
          проводятся понарошку, реальные деньги ни с кого не спишут) Когда всё будет готово и вы убедитесь в
          работспособности всего процесса подмените ключи на указанные в продакшен окружении https://payhub.one (здесь
          все операции реальные)
        </p>
        <h2>Трейдер</h2>
        <p>
          Трейдер — человек осуществляющий запрошенные мерчантом клиентские виздравы и клиентские депозиты. Он либо
          переводит деньги клиенту мерчанта на банковскую карту, либо принимает деньги от клиента мерчанта на свою
          карту.
        </p>
        <p>
          Для начала работы необходимо запросить у менджера нашей платформы создание аккаунта. Далее вам необходимо в
          настройках указать доступные реквизиты ваших банковский карт (номер карты и имя) и криптовалютные кошельки
          (номер кошель). Затем пополнить свой свободный баланс путём создания трейдерского депозита, чтобы иметь
          возможность участвовать в клиентских депозитах. Затем со свободного баланса пополнить свободный страховой
          баланс путём создания трейдерского страхового депозита, чтобы иметь возможность участвовать в клиентских
          виздравах.
        </p>
        <p>
          Если вы хотите, чтобы вас автоматически назначали на новые клиентские депозиты нажмите на дашборде кнопку «Я
          работаю». В таком случае, при наличии на вашем свободном балансе нужной суммы и наличии соответсвующего
          платёжного метода вы автоматически будете назначены на клиентский депозит. Когда соберётесь отойти от
          компьютера, не забудьте нажать на кнопку «Я отдыхаю», чтобы перестать быть автоматически назначемым на новые
          клиентские депозиты.
        </p>
        <h2>Клиентский депозит</h2>
        <p>
          Сначала клиентский депозит создаётся через апи мерчантом. В результате чего генерируется ссылка для клиента
          мерчанта, но которую этот клиент будет автоматически перенаправлен программными средствами сайта мерчанта. В
          момент создания сделки будет отправлен эвент {toHumanWebhookEventName('customerDepositCreated')} на указанный
          адрес вебхука мерчанта.
        </p>
        <p>
          Если есть трейдер в рабочем режиме с нужной суммой на свободном балансе и подходящим платёжным методом, он
          будет назначен автоматически. И соответственно клиент мерчанта сразу после направления по ссылке увидит
          реквизиты карты трейдера, на которую надо перевести деньги.
        </p>
        <p>
          Если подходящий трейдер не нашёлся автоматически, тогда клиент увидит на своей страницы информацию о том, что
          происходит поиск исполнителя сделки, а менеджер платформы в срочном порядке будет искать подходящего трейдера.
        </p>
        <p>
          Если подходящий трейдер не найдётся в течение 20 минут с момента создания сделки, сделка будет отменена
          автоматически.
        </p>
        <p>
          В момент, когда трейдер берётся за сделку внутри плафтормы создаётся транзакция{' '}
          {toHumanTxType('cd_body_tf_th_dealPick')}. Таким бразом трейдер не может никаким образом выйти за пределы
          своего свободного баланса набирая больше сделок на клиентский депозит, чем это возможно в безопасных для
          мерчанта и плафтормы пределах.
        </p>
        <p>
          После того как клиент увдит реквизиты для перевода, он должен осуществить перевод в течение 20 минут и нажать
          на кнопку «Я оплатил». Если он не успеет, сделка будет отменена автоматически. В случае отмены создаётся
          транзакция {toHumanTxType('cd_body_th_tf_dealCancelProcessing')}. Таким образом ранее замороженная часть
          свободного баланса трейдера размораживается.
        </p>
        <p>
          После нажатия клиентом на кнопку «Я оплатил» трейдер должен подтвердить получение средств. Это можно сделать
          как вручную, так и через подключение автоматики в андроид приложении.
        </p>
        <p>
          Если трейдер утверждает, что деньги не приходят, он открывает спор. Чтобы урегулировать спор приходит админ и
          разбирается в ситуации. Если оказывается, что деньги реально не пришли, тогда спор закрывается в пользу
          трейдера и сделка отменяется и также создаётся транзакция{' '}
          {toHumanTxType('cd_body_th_tf_dealCancelProcessing')}. Если оказывается, что деньги всё же пришли, то спор
          отменяется, а сделка возвращается обратно в статус проверки, после чего она будет одобрена и завершена.
        </p>
        <p>
          Если деньги просто пришли сразу, то трейдер просто подтверждает завершение сделки, после чего сделка считается
          завершённой. В момент завершения сделки будет отправлен эвент
          {toHumanWebhookEventName('customerDepositCompleted')} на указанный адрес вебхука мерчанта.
        </p>
        <p>
          В момент успешного завершения сделки по клиентскому депозиту создются следующие транзакции:{' '}
          {toHumanTxType('cd_body_th_mf_dealComplete')}, {toHumanTxType('cd_fee_mf_cf_dealComplete')},{' '}
          {toHumanTxType('cd_interest_cf_tf_dealComplete')}. А также бонусные транзакции при наличии структуры
          пригласиваших: {toHumanTxType('cd_bonus_r1_cf_tf_dealComplete')},{' '}
          {toHumanTxType('cd_bonus_r2_cf_tf_dealComplete')}, {toHumanTxType('cd_bonus_r3_cf_tf_dealComplete')},{' '}
          {toHumanTxType('cd_bonus_t_cf_tf_dealComplete')}.
        </p>
        <h2>Клиентский виздрав</h2>
        <p>
          Сначала клиентский виздрав создаётся через апи мерчантом. В результате чего генерируется ссылка для клиента
          мерчанта, но которую этот клиент будет автоматически перенаправлен программными средствами сайта мерчанта. В
          момент создания сделки будет отправлен эвент
          {toHumanWebhookEventName('customerWithdrawCreated')} на указанный адрес вебхука мерчанта.
        </p>
        <p>
          В момент создания на свободном счету мерчанта должна быть соответствующая сумма. Если на балансе недостаточно
          денег, в ответ на апи запрос вы увидите ошибку. В случае успешного создания клиентского виздрава будут созданы
          транзакции: {toHumanTxType('cw_body_mf_mh_dealStart')}, {toHumanTxType('cw_fee_mf_mh_dealStart')}.
        </p>
        <p>
          В момент успешного создания сделки генерируется ссылка для клиента мерчанта, но которую этот клиент будет
          автоматически перенаправлен программными средствами сайта мерчанта.
        </p>
        <p>Клиент увидит на своей странице информацию о том, что происходит поиск исполнителя сделки.</p>
        <p>
          Если подходящий трейдер не найдётся в течение 20 минут с момента создания сделки, сделка будет отменена
          автоматически. В этом случае будут созданы транакции {toHumanTxType('cw_body_mh_mf_dealCancelPicking')},{' '}
          {toHumanTxType('cw_fee_mh_mf_dealCancelPicking')}.
        </p>
        <p>
          Трейдер может взяться за сделку только если у него есть достаточно средств на свободном страховом балансе. В
          момент взятия трейдером за сделку создаётся транзакция {toHumanTxType('cw_body_tif_tih_dealPick')}
        </p>
        <p>
          Когда трейдер возьмётся за эту сделку, он сразу же увидит реквизиты клиента, на которые трейдер должен сделать
          перевод. А клиент увидит информацию о том, что трейдер взялся за сделку и скоро сделает перевод.
        </p>
        <p>
          Если в реквизитах клиента есть какая-то ошибка или что-то ещё мешает трейдеру сделать перевод он может
          отменить сделку. В таком случае будут созданы транзакции:{' '}
          {toHumanTxType('cw_body_mh_mf_dealCancelProcessing')}, {toHumanTxType('cw_fee_mh_mf_dealCancelProcessing')},{' '}
          {toHumanTxType('cw_body_tih_tif_dealCancelProcessing')}.
        </p>
        <p>
          Если трейдер успешно сделает перевод, то он сразу же закрывает сделку и она считается завершённой. В этом
          случае создаются транзакции: {toHumanTxType('cw_body_tih_tif_dealComplete')},{' '}
          {toHumanTxType('cw_body_mh_tf_dealComplete')}, {toHumanTxType('cw_fee_mh_cf_dealComplete')},{' '}
          {toHumanTxType('cw_interest_cf_tf_dealComplete')}. А также бонусные транзакции при наличии структуры
          пригласиваших: {toHumanTxType('cw_bonus_r1_cf_tf_dealComplete')},{' '}
          {toHumanTxType('cw_bonus_r2_cf_tf_dealComplete')}, {toHumanTxType('cw_bonus_r3_cf_tf_dealComplete')},{' '}
          {toHumanTxType('cw_bonus_t_cf_tf_dealComplete')}. В момент завершения сделки будет отправлен эвент
          {toHumanWebhookEventName('customerWithdrawCreated')} на указанный адрес вебхука мерчанта.
        </p>
        <p>
          Если клиент мерчанта в ближайшее время не увидит в своём банке этого перевода он может открыть спор. В этом
          случае осздадутся транзакции: {toHumanTxType('cw_fee_cf_ch_disputeStart')},{' '}
          {toHumanTxType('cw_body_tif_tih_disputeStart')}, {toHumanTxType('cw_body_tf_th_disputeStart')},{' '}
          {toHumanTxType('cw_interest_tif_tih_disputeStart')}, {toHumanTxType('cw_interest_tf_th_disputeStart')},{' '}
          {toHumanTxType('cw_bonus_t_tf_th_disputeStart')}.
        </p>
        <p>
          Далее придёт админ и будет разбираться. Если окажется, что деньги реально не были отправлены клиенту тредером,
          то спор закроется в пользу клиента, в результате чего будут созданы транзакции{' '}
          {toHumanTxType('cw_body_tih_mf_disputeComplete')}, {toHumanTxType('cw_body_th_tif_disputeComplete')},{' '}
          {toHumanTxType('cw_interest_tih_cf_disputeComplete')}, {toHumanTxType('cw_interest_th_tif_disputeComplete')},{' '}
          {toHumanTxType('cw_fee_ch_mf_disputeComplete')}. А также транзакции по возвращению бонусов при наличии
          структуры пригласивших: {toHumanTxType('cw_bonus_r1_tf_cf_disputeComplete')},{' '}
          {toHumanTxType('cw_bonus_r2_tf_cf_disputeComplete')}, {toHumanTxType('cw_bonus_r3_tf_cf_disputeComplete')},{' '}
          {toHumanTxType('cw_bonus_t_th_cf_disputeComplete')}. Сделка перейдёт в статус «Возвращён». В момент возврата
          средств будет отправлен эвент
          {toHumanWebhookEventName('customerWithdrawRefunded')} на указанный адрес вебхука мерчанта.
        </p>
        <p>
          Если в результате спора окажется, что деньги реально были переведены, то спор будет отменён и сделка обратно
          вренётся в статус завершённой. Тогда будут созданы транзакции: {toHumanTxType('cw_fee_ch_cf_disputeCancel')},{' '}
          {toHumanTxType('cw_body_tih_tif_disputeCancel')}, {toHumanTxType('cw_interest_tih_tif_disputeCancel')},{' '}
          {toHumanTxType('cw_body_th_tf_disputeCancel')}, {toHumanTxType('cw_interest_th_tf_disputeCancel')},{' '}
          {toHumanTxType('cw_bonus_t_th_tf_disputeCancel')}.
        </p>
        <h2>Трейдерский депозит</h2>
        <p>
          Трейдерский депозит создаётся трейдером из личного кабинета. Далее админ должен за него взяться. Когда админ
          за него возьмётся, трейдер увидит номер usdt кошелька, на который должен будет перевести указанную сумму. Или
          вместо перевода в usdt, админ может запросить код гарантекса, тогда трейдер должен будет его сгенерировать и
          отправить. После админ должен подтвердить получение денег. В результате успешного завершения сделки будут
          созданы транзакции: {toHumanTxType('td_body_te_tf_dealComplete')},{' '}
          {toHumanTxType('td_body_void_cb_dealComplete')}.
        </p>
        <h2>Трейдерский виздрав</h2>
        <p>
          По идее трейдерский виздрав должен позволять вывести деньги со своего свободного баланса платформы на свой
          usdt кошелёк. Но сейчас эта функция отключена с целью мотивировать трейдеров выводить деньги со своего
          свободного баланса путём участия в клиентских депозитах.
        </p>
        <h2>Трейдерский страховой депозит</h2>
        <p>
          Способ перевода денег со свободного баланса трейдера на его свободный страховой баланс. Пополнять свободный
          страховой баланс нужно для того, чтобы иметь возможность участвовать в клиентских виздравах. Создаётся эта
          сделка из личного кабинета самим трейдером и исполняется мгновенно, в результате чего создаётся транзакция{' '}
          {toHumanTxType('tid_body_tf_tif_dealComplete')}.
        </p>
        <h2>Трейдерский страховой виздрав</h2>
        <p>
          Способ вывода денег со свободного страхового баланса трейдера на его обычный свободный баланс. Сделка
          создаётся через личный кабинет самим трейдером. В этот момент создаётся транзакция{' '}
          {toHumanTxType('tiw_body_tif_tih_dealStart')}.
        </p>
        <p>
          До тех пор, пока админ не взялся за эту сделку трейдер может передумать и сам отменить её. Тогда будет создана
          транзакция {toHumanTxType('tiw_body_tih_tif_dealCancelPicking')}
        </p>
        <p>
          Далее админ должен взяться за эту сделку и решить, можно ли сейчас вывести этому трейдеру деньги со страхового
          баланса. Решение принимается индивидуально по отношению к каждому трейдеру в зависимости от того, были ли у
          него недавно сделки, по котором в ближайшем будущем ещё могут быть открыты споры клиентами мерчанта.
        </p>
        <p>
          В случае одобрения админом сделки будет создана транзакция {toHumanTxType('tiw_body_tih_tf_dealComplete')}.
        </p>
        <p>
          Если админ решает, что сейчас невозможно вывести деньги для трейдера со страхового баланса он отменяет сделку
          через мгновенное создание и завершение спора, чем создаёт транзакцию{' '}
          {toHumanTxType('tiw_body_tih_tif_dealCancelProcessing')}
        </p>
        <h2>Мерчантский депозит</h2>
        <p>
          Мерчантский депозит создаётся сотрудником мерчанта из личного кабинета. Далее админ должен за него взяться.
          Когда админ за него возьмётся, сотрудник мерчанта увидит номер usdt кошелька, на который должен будет
          перевести указанную сумму. Или вместо перевода в usdt, админ может запросить код гарантекса, тогда сотрудник
          мерчанта должен будет его сгенерировать и отправить. После админ должен подтвердить получение денег. В
          результате успешного завершения сделки будут созданы транзакции: {toHumanTxType('md_body_me_mf_dealComplete')}
          , {toHumanTxType('md_body_void_cb_dealComplete')}.
        </p>
        <h2>Мерчантский виздрав</h2>
        <p>
          Способ вывода денег со свободного баланса мерчанта. Сделка создаётся через личный кабинет самим сотрудником
          мерчанта. В этот момент создаётся транзакция {toHumanTxType('mw_body_mf_mh_dealStart')}.
        </p>
        <p>
          До тех пор, пока админ не взялся за эту сделку сотрудник мерчанта может передумать и сам отменить её. Тогда
          будет создана транзакция {toHumanTxType('mw_body_mh_mf_dealCancelPicking')}
        </p>
        <p>Далее админ должен взяться за эту сделку и сделать перевод по указанным реквизитам.</p>
        <p>
          Если в реквизитах есть какая-то ошибка или что-то ещё мешает админу сделать перевод он может отменить сделку в
          вроцессе. В таком случае будет создана транзакция {toHumanTxType('mw_body_mh_mf_dealCancelProcessing')}.
        </p>
        <p>
          Если админ успешно делает перевод, то он завершает сделку в результате чего создаются транзакции:{' '}
          {toHumanTxType('mw_body_mh_me_dealComplete')}, {toHumanTxType('mw_body_cb_void_dealComplete')}.
        </p>
        <p>
          Если сотрудник мерчанта не получил деньги, он может открыть спор. В результате чего будет создана транзакция:{' '}
          {toHumanTxType('mw_body_cf_ch_disputeStart')}.
        </p>
        <p>
          Далее если админ одобрит спор в пользу мерчанта, будет созданы транзакции:{' '}
          {toHumanTxType('mw_body_ch_mf_disputeComplete')}, {toHumanTxType('mw_body_void_cb_disputeComplete')}
        </p>
        <p>
          Если админ убедится, что деньги всё же были успешно отправлены, он отменит спор. Врезультате чего будет
          создана транзакция {toHumanTxType('mw_body_ch_cf_disputeCancel')}
        </p>
      </RichText>
    </Segment>
  )
})
