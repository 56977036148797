import { ActiveMerchantDeposits } from './components/ActiveMerchantDeposits.js'
import { ActiveMerchantWithdraws } from './components/ActiveMerchantWithdraws.js'
import { pageData } from './pageData.js'
import { integerWithDecimalsToAmountString, toMoney } from '@/general/src/other/money.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, LabeledValues, Segment, Segments } from '@/webapp/src/lib/uninty.components.js'

export const MerchantDashboardPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedMerchantMembersOnly: true,
  showLoaderOnFetching: false,
  useQueries: () => {
    return trpc.useQueries((t) => [
      t.getMe(),
      t.getMyMerchant(undefined, {
        refetchInterval: 3_000,
      }),
    ])
  },
  setProps: ({ getAuthorizedMerchantMemberMe, queriesResults }) => ({
    me: getAuthorizedMerchantMemberMe(),
    merchant: queriesResults[1].data.merchant,
  }),
})(({ merchant }) => {
  return (
    <Block fcnw>
      <Segment title="Мерчантский дашборд" size="m">
        <Segments>
          <Segment title="Баланс" size="s">
            <LabeledValues
              $style={{
                labelColor: 'var(--mantine-color-text)',
                hintColor: 'var(--mantine-color-text)',
              }}
              items={[
                ['Свободно', toMoney({ amount: merchant.balanceUsdtFree, currency: 'usdt' })],
                ['Заморожено', toMoney({ amount: merchant.balanceUsdtHolded, currency: 'usdt' })],
              ]}
            />
          </Segment>
          <Segment title="Комиссия" size="s">
            <LabeledValues
              $style={{
                labelColor: 'var(--mantine-color-text)',
                hintColor: 'var(--mantine-color-text)',
              }}
              items={[
                [
                  'Клиентский виздрав',
                  integerWithDecimalsToAmountString({ amount: merchant.customerWithdrawFeeShare }) + '%',
                ],
                [
                  'Клиентский депозит',
                  integerWithDecimalsToAmountString({ amount: merchant.customerDepositFeeShare }) + '%',
                ],
              ]}
            />
          </Segment>
          <Segment title="Мои текущие мерчантские виздравы" size="s">
            <ActiveMerchantWithdraws />
          </Segment>
          <Segment title="Мои текущие мерчантские депозиты" size="s">
            <ActiveMerchantDeposits />
          </Segment>
        </Segments>
      </Segment>
    </Block>
  )
})
