import { ActiveCustomerDeposits } from './components/ActiveCustomerDeposits.js'
import { ActiveCustomerWithdraws } from './components/ActiveCustomerWithdraws.js'
import { ActiveTraderDeposits } from './components/ActiveTraderDeposits.js'
import { ActiveTraderInsuranceWithdraws } from './components/ActiveTraderInsuranceWithdraws.js'
import { AvailableCustomerDeposits } from './components/AvailableCustomerDeposits.js'
import { AvailableCustomerWithdraws } from './components/AvailableCustomerWithdraws.js'
import { pageData } from './pageData.js'
import { integerWithDecimalsToAmountString, toMoney } from '@/general/src/other/money.js'
import { GeneralLayout } from '@/webapp/src/components/layout/GeneralLayout/index.js'
import { withPageWrapper } from '@/webapp/src/lib/pageWrapper.js'
import { trpc } from '@/webapp/src/lib/trpc.js'
import { Block, LabeledValues, Segment, Segments } from '@/webapp/src/lib/uninty.components.js'
import { StatusEditor } from '@/webapp/src/pages/trader/other/TraderDashboardPage/components/StatusEditor.js'

export const TraderDashboardPage = withPageWrapper({
  title: pageData.title,
  Layout: GeneralLayout,
  authorizedTradersOnly: true,
  setProps: ({ getAuthorizedTraderMe }) => ({
    me: getAuthorizedTraderMe(),
  }),
  showLoaderOnFetching: false,
  useQuery: () =>
    trpc.getMe.useQuery(undefined, {
      refetchInterval: 3_000,
    }),
})(({ me }) => {
  return (
    <Block fcnw>
      <Segment title="Трейдерский дашборд" size="m">
        <Segments>
          <Segment title="Баланс" size="s">
            <LabeledValues
              $style={{
                labelColor: 'var(--mantine-color-text)',
                hintColor: 'var(--mantine-color-text)',
              }}
              items={[
                ['Свободно', toMoney({ amount: me.trader.balanceUsdtFree, currency: 'usdt' })],
                ['Заморожено', toMoney({ amount: me.trader.balanceUsdtHolded, currency: 'usdt' })],
                ['Свободная страховка', toMoney({ amount: me.trader.balanceUsdtInsuranceFree, currency: 'usdt' })],
                ['Замороженная страховка', toMoney({ amount: me.trader.balanceUsdtInsuranceHolded, currency: 'usdt' })],
              ]}
            />
          </Segment>
          <Segment title="Комиссия" size="s">
            <LabeledValues
              $style={{
                labelColor: 'var(--mantine-color-text)',
                hintColor: 'var(--mantine-color-text)',
              }}
              items={[
                [
                  'Клиентский виздрав',
                  integerWithDecimalsToAmountString({ amount: me.trader.customerWithdrawInterestShare }) + '%',
                ],
                [
                  'Клиентский депозит',
                  integerWithDecimalsToAmountString({ amount: me.trader.customerDepositInterestShare }) + '%',
                ],
              ]}
            />
          </Segment>
          <StatusEditor me={me} />
          <Segment title="Свободные клиентские виздравы" size="s">
            <AvailableCustomerWithdraws me={me} />
          </Segment>
          <Segment title="Свободные клиентские депозиты" size="s">
            <AvailableCustomerDeposits me={me} />
          </Segment>
          <Segment title="Мои текущие клиентские виздравы" size="s">
            <ActiveCustomerWithdraws me={me} />
          </Segment>
          <Segment title="Мои текущие клиентские депозиты" size="s">
            <ActiveCustomerDeposits me={me} />
          </Segment>
          <Segment title="Мои текущие трейдерские депозиты" size="s">
            <ActiveTraderDeposits />
          </Segment>
          <Segment title="Мои текущие трейдерские страховые виздравы" size="s">
            <ActiveTraderInsuranceWithdraws />
          </Segment>
        </Segments>
      </Segment>
    </Block>
  )
})
