import type { TrpcRouterOutput } from '@/backend/src/router/trpc/index.js'
import { ApiDocsSection } from '@/general/src/api/docs/ApiDocsSection.js'

export const ApiDocsCreateCustomerWithdraw = ({
  merchant,
}: {
  merchant?: TrpcRouterOutput['getMyMerchant']['merchant'] | null
}) => {
  return (
    <ApiDocsSection
      merchant={merchant}
      title="Создание клиентского виздрава"
      desc="Клиентский виздрав — способ для клиента мерчанта получить деньги себе на карту от трейдера платформы, в данном случае эквивалентная сумма в USDT спишется со свободного баланса мерчанта. Отправьте запрос с вашего сервера. Из ответа вытащите dealUrl и перенаправьте туда своего пользователя. В случае успешного заверения сделки получите соответствующий вебхук."
      method="POST"
      path="/api/create-customer-withdraw/v1"
      hashParams={[
        'email',
        'amount',
        'currency',
        'paymentMethodType',
        'wallet',
        'cardNumber',
        'ownerName',
        'ownerPhone',
        'successUrl',
        'failUrl',
      ]}
      bodyParams={[
        {
          key: 'idempotencyKey',
          title: 'idempotencyKey*',
          desc: 'Обязательное поле - ключ для дедубликации одинаковых запросов (если пользователь дважды нажмет Отправить). Как правило, ID платежа в вашей системе.',
          defaultValue: 'abcdefghijklmnopqrstuvwxyz',
        },
        {
          key: 'email',
          title: 'email',
          desc: 'E-mail клиента мерчанта, ему туда будет отправлена ссылка на оплату, если вы передадите его',
        },
        {
          key: 'currency',
          title: 'currency',
          desc: 'Валюта, в которой мерчант хочет списать деньги в своей системе со своего клиента. Сейчас поддерживается только "rub"',
          defaultValue: 'rub',
        },
        {
          key: 'amount',
          title: 'amount',
          desc: 'Сумма в указанной валюте с указанием копеек (центов) без знака запятой. То есть 1000 рублей — 100000. 1000 рублей 50 копеек — 100050',
          defaultValue: '100000',
        },
        {
          key: 'paymentMethodType',
          title: 'paymentMethodType',
          desc: 'Способ оплаты, например "sberbankCard". См. метод /api/get-payment-methods/v1',
          defaultValue: 'sberbankCard',
        },
        {
          key: 'cardNumber',
          title: 'cardNumber',
          desc: 'Обязателен, если выбран метод оплаты с карты. На нашей стороне проходит валидацию на опечатки в номере карты по алгоритму луна',
          defaultValue: '4242424242424242',
        },
        {
          key: 'ownerPhone',
          title: 'ownerPhone',
          desc: 'Обязателен, если выбран метод оплаты по СБП. На нашей стороне проходит валидацию на базовую валидность',
          defaultValue: '',
        },
        {
          key: 'ownerName',
          title: 'ownerName',
          desc: 'Обязателен, если выбран метод оплаты с карты или СБП',
          defaultValue: 'Иван Иванов',
        },
        {
          key: 'wallet',
          title: 'wallet',
          desc: 'Сейчас не передаётся. В будущем будет использоваться для вывода на крипто-кошелёк',
          defaultValue: '',
        },
        {
          key: 'successUrl',
          title: 'successUrl',
          desc: 'URL, на который мы перенаправим клиента после успешного завершения сделки. В урле можно подставить строку "$customerWithdrawId", она будет заменена на id созданного клиенсткого виздрава',
          defaultValue: 'https://yandex.ru?id=$customerWithdrawId',
        },
        {
          key: 'failUrl',
          title: 'failUrl',
          desc: 'URL, на который мы перенаправим клиента в случае провала или отмены. В урле можно подставить строку "$customerWithdrawId", она будет заменена на id созданного клиенсткого виздрава',
          defaultValue: 'https://google.com?id=$customerWithdrawId',
        },
      ]}
      responseExample={{
        customerWithdraw: {
          id: '01914b95-5032-7990-956c-328f7d348943',
          serialNumber: 2,
          createdAt: '2024-08-13T11:52:42.285Z',
          pickedAt: null,
          cancelledAt: null,
          completedAt: null,
          disputeStartedAt: null,
          disputeCompletedAt: null,
          disputeCancelledAt: null,
          willBeCancelledBecauseNotPickedAt: '2024-08-13T12:12:42.307Z',
          refundedAt: null,
          cancellationCode: null,
          cancellationMessage: null,
          disputeStartMessage: null,
          customerPaymentMethodProvider: 'sberbank',
          paymentMethodGroup: 'card',
          paymentMethodType: 'sberbankCard',
          status: 'picking',
          successUrl: 'https://yandex.ru?id=01914b95-5032-7990-956c-328f7d348943',
          failUrl: 'https://google.com?id=01914b95-5032-7990-956c-328f7d348943',
          dealUrl:
            'https://example.com/customer/withdraw/view/01914b95-5032-7990-956c-328f7d348943/eddf976d-f931-45db-bf00-5aced7b52ca9',
          amountUsdt: 1_082,
          dealCurrency: 'rub',
          dealCurrencyInOneUsdt: 9_238,
          dealAmount: 100_000,
          merchantCurrency: 'rub',
          merchantCurrencyInOneUsdt: 9_238,
          merchantAmount: 100_000,
          merchantFeeShare: 600,
          merchantFeeUsdt: 64,
          trader: null,
          isActive: true,
          finishedAt: null,
          chatId: '3285d77e-c2e5-4b0b-8c24-301cbfabbaaf',
          demo: false,
        },
      }}
    />
  )
}
